import React, { useRef, useEffect, useState } from "react";
import { Row, Col, Form, Button, InputGroup, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faEnvelope,
  faFileInvoice,
  faHome,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import logoSaleNoti from "../../assets/user/images/logoSaleNoti.png";

function Footer() {
  const formSDT1 = useRef();
  const [posts, setPost] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    getAllPost();
  }, [navigate]);
  const getAllPost = async () => {
    await axios
      .get(`/getPostsByTopics/chinh-sach`)
      .then((res) => {
        if (res.data.errCode === 0) {
          setPost(res.data.posts[0]?.posts);
        } else {
          toast(res.data.message, { type: "error" });
        }
      })
      .catch((error) => {
        toast(error.message, { type: "error" });
      });
  };
  const sendSDT = (e) => {
    if (formSDT1.current.phone.value.toString().length > 9) {
      emailjs
        .sendForm(
          "service_fihg5vx",
          "template_es4vjjf",
          formSDT1.current,
          "tt8EBINZkDg6uPMLi"
        )
        .then(
          () => {
            navigate("/dang-ky-thanh-cong");

            toast(`Đăng ký thành công`, {
              type: "success",
              autoClose: 1000,
              style: { marginTop: "50px" },
            });
            formSDT1.current.phone.value = "";
          },
          (error) => {
            console.log(error.text);
          }
        );
    } else {
      toast(`Vui lòng nhập số điện thoại đúng định dạng`, {
        type: "error",
        autoClose: 1000,
        style: { marginTop: "50px" },
      });
    }
  };

  return (
    <footer className="mt-auto footer_home">
      <Container>
        <Row className="row footer-top clearfix">
          <Col
            lg={{ span: 3, offset: 2 }}
            md={6}
            className="d-flex align-items-center"
          >
            <h4 className="text-dark mb-md-0 ">
              Đăng ký dùng thử miễn phí 7 ngày?
            </h4>
          </Col>

          <Col lg={5} md={6}>
            <Form ref={formSDT1}>
              <InputGroup>
                <Form.Control
                  style={{ borderRadius: "50px", height: "50px" }}
                  placeholder="Nhập số điện thoại..."
                  name="phone"
                />
                <pre> </pre>
                <Button
                  variant="primary"
                  onClick={() => sendSDT()}
                  style={{
                    borderRadius: "50px",
                    height: "50px",
                    marginLeft: ".5rem",
                    padding: "0 1rem",
                  }}
                >
                  Đăng ký tư vấn
                </Button>
              </InputGroup>
            </Form>
          </Col>
        </Row>

        <Row className=" footer-link-box clearfix">
          <Col md={4} className="left-f-box">
            <h4 className="fw-bold">CÔNG TY CỔ PHẦN CÔNG NGHỆ KATEC</h4>
            <ul>
              <li>
                <FontAwesomeIcon icon={faHome} />
                &nbsp; Số 189, đường Phan Huy Chú, phường An Khánh, quận Ninh
                Kiều, thành phố Cần Thơ.
              </li>
              <li>
                <FontAwesomeIcon icon={faPhone} />
                &nbsp;
                <Link
                  to="tel: 0889881010"
                  target="_blank"
                  title="Gọi hotline tư vấn."
                >
                  0889 88 1010
                </Link>{" "}
                -{" "}
                <Link
                  to="tel: 0849881010"
                  target="_blank"
                  title="Gọi hotline tư vấn."
                >
                  0849 88 1010
                </Link>
              </li>
              <li>
                <FontAwesomeIcon icon={faEnvelope} />
                &nbsp; katec.cantho@gmail.com
              </li>
              <li>
                <FontAwesomeIcon icon={faFileInvoice} /> MST: 1801633969
              </li>
              <li>
                <FontAwesomeIcon icon={faBuilding} /> Giấy phép kinh doanh: Số
                1801633969 ngày cấp 04/04/2019 thay đổi lần thứ nhất là
                11/01/2022, nơi cấp Sở Kế Hoạch và Đầu Tư TP Cần Thơ
              </li>
            </ul>
          </Col>
          <Col md={4} className="left-f-box">
            <h4 className="fw-bold">HƯỚNG DẪN KHÁCH HÀNG</h4>
            <ul>
              {posts?.map((item) => {
                return (
                  <li>
                    <Link to={`chinh-sach/${item.slug}`}>{item.titleVi}</Link>
                  </li>
                );
              })}
            </ul>
          </Col>

          <Col md={4} className="left-f-box">
            <h4 className="fw-bold">
              <FontAwesomeIcon icon={faLocationDot} /> GOOGLE MAP
            </h4>
            <iframe
              title="Google map Katec"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3928.706498664805!2d105.75135877421363!3d10.041060572328492!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31a0886c3ecc3e01%3A0x5a84e770728f1669!2zMTg5IMSQLiBQaGFuIEh1eSBDaMO6LCBQaMaw4budbmcgQW4gS2jDoW5oLCBOaW5oIEtp4buBdSwgQ-G6p24gVGjGoSwgVmnhu4d0IE5hbQ!5e0!3m2!1svi!2s!4v1683166794218!5m2!1svi!2s"
              width="100%"
              height="120"
              style={{ border: "0" }}
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>

            <Link to='http://online.gov.vn/Home/WebDetails/116747' title='Đã đăng ký Bộ công thương - Kafood' >
              <img style={{ width: '50%' }} alt='Đã đăng ký Bộ công thương - Kafood' title='Đã đăng ký Bộ công thương - Kafood' src={logoSaleNoti} />
            </Link>
          </Col>
        </Row>
      </Container>
      <img
        src="../../../assets/user/images/product/detail/footer.png"
        className="icon-small"
        alt=""
      />

      <div className="copyright">
        <p className="text-center">
          {" "}
          Copyright © 2022 Kafood. Một sản phẩm của{" "}
          <Link to="https://katec.vn/" target="_blank">
            {" "}
            Katec.
          </Link>
        </p>
      </div>
    </footer>
  );
}

export default Footer;
